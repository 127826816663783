<nav class="navbar-container navbar navbar-expand border-bottom">
    <div class="container-fluid px-0">
        <div class="collapse navbar-collapse">

            <h4 class="fw-bold me-auto mb-0 ms-7 ps-7 ms-lg-0 ps-lg-0 text-primary">
                <span class="d-block px-2">
                    {{ ("NAVBAR." + componentPageHeader) | translate }}
                </span>
            </h4>

            <div class="user-bar d-flex align-items-center" *ngIf="profile">
                <div ngbDropdown>
                    <button type="button" class="user-dropdown btn btn-outline-primary border-0 fw-bold"
                    [matMenuTriggerFor]="optionMenu"
                        ngbDropdownToggle>
                        <img [src]="profile?.photo_url | token" alt="avatar person" class="avatar border me-3">
                        <span class="d-none d-lg-inline-block">{{ profile | fullName }}</span>
                    </button>
                    <mat-menu class="option-menu" #optionMenu="matMenu" xPosition="before">
                        <div class="top-menu">
                            <button mat-menu-item [routerLink]="['/profile']" routerLinkActive="menu-active" [routerLinkActiveOptions]="{ exact: true }" >
                              <mat-icon svgIcon="icon-user"></mat-icon>
                                 <span>{{ "NAVBAR.LIST.PROFILE" | translate }}</span>
                            </button>
                            <button  mat-menu-item class="title" (click)="changeLang()">
                                <mat-icon svgIcon="icon-lang"></mat-icon>
                                <span> {{ "NAVBAR.LANGUAGE" | translate }} </span>
                              </button>
                            <mat-divider/>
                            <button  mat-menu-item class="title" (click)="openConfirmModal()">
                              <mat-icon svgIcon="icon-leave"></mat-icon>
                              <span style="color:red;"> {{ "NAVBAR.LIST.LOGOUT" | translate }} </span>
                            </button>
                          </div>
                    </mat-menu>
                </div>
            </div>

        </div>
    </div>
</nav>