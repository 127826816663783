import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { BaseCrudService } from './base-crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { User, UserSort} from 'src/models/user';
import { RequestPagination } from 'src/models/request-pagination';
import { IEditUserRequest } from "src/util/user"

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseCrudService<any>{

    constructor(injector: Injector) {
        super(injector);
        this.path = `${environment.portal_url}/users/profile`;
    }

    getAboutUser(): Observable<User> {
        return this.requestService.getJSON(`${this.path}/about`);
    }

    getAllUser(data: RequestPagination): Observable<UserSort[]> {
        return this.requestService.getJSON(`${environment.auth_url}/users/all/role`, {data})
    }

    delete(_id: string): Observable<any> {
        return this.requestService.deleteJSON(`${environment.auth_url}/users/${_id}`)
    }

    getRole(): Observable<any> {
        return this.requestService.getJSON(`${environment.auth_url}/roles`);
    }

    get(_id: string): Observable<any> {
        return this.requestService.getJSON(`${environment.auth_url}/user?id=${_id}`)
    }

    edit(_id: string , data: IEditUserRequest): Observable<any> {
        return this.requestService.putJSON(`${environment.auth_url}/users/edit/profile/${_id}`, {data})
    }
}