import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LocalStorageEnum } from 'src/models/enums/local-storage.enum';
import { User } from 'src/models/user';
import { AuthService } from 'src/services/auth.service';
import { LocalStorageService } from 'src/services/local-storage.service';
import { UserService } from 'src/services/user.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ConfirmModal } from 'src/models/confirm-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  isAuth: Boolean;
  authSubscribe: Subscription;
  profile: User;

  componentPageHeader: string;

  /**
   * Language Listing
   */
  listLang = [
    { text: 'English', flag: 'assets/icons/en-flag.svg', lang: 'en' },
    { text: 'ខ្មែរ', flag: 'assets/icons/km-flag.svg', lang: 'km' }
  ];
  lang : string = 'km'
  cookieValue: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService, 
    private modalService: NgbModal,
  ) {
    this.authSubscribe = this.authService.getAuth().subscribe((isAuth) => {
      this.isAuth = isAuth;
      if (this.isAuth) {
        this.userService.getAboutUser().subscribe((data) => {
          this.profile = data;
        });
      }
    });

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        const urlAfterRedirects = event.urlAfterRedirects;
        const arr_split = urlAfterRedirects.split('/');
        this.componentPageHeader = arr_split[1];
      }
    });
  }

  ngOnInit(): void {
    // Cookies wise Language set
    let lang = this.localStorageService.get(LocalStorageEnum.lang) ? this.localStorageService.get(LocalStorageEnum.lang) : 'km';
    this.setLanguage(lang);
  }

  /**
   * Cookie Language set
   */
  setLanguage(lang: string) {
    this.cookieValue = lang;
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.localStorageService.set(LocalStorageEnum.lang, lang);
  }

  logout() {
    this.isAuth = false;
    this.authSubscribe.unsubscribe();
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  ngOnDestroy(): void {
    this.authSubscribe.unsubscribe();
  }

  openConfirmModal() {
    const data: ConfirmModal = {
      title: 'NAVBAR.LIST.LOGOUT',
      icon: '/assets/icons/mobile-logout.svg',
      message: this.translate.instant("ConfirmMessage.Logout"),
      button: 'remove'
    };


    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true });
    modalRef.componentInstance.data = data;
    modalRef.result.then((result) => {

      if (result == "confirmed") {
        this.logout();
      }
    }, (reason) => {
      console.error(`Dismissed ${reason}`);
    });
  }
  changeLang(){
    this.lang  = this.lang == 'km' ? 'en' : 'km'
    this.setLanguage(this.lang)
  }

}
